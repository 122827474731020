// global imports
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
// components
import App from './App'
// styling
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'

process.env.REACT_APP_SENTRY_ENABLED &&
  Sentry.init({
    dsn: 'https://b407fe23c4ba4f39a3b3a4d03beaed68@o914254.ingest.sentry.io/5854682',
    integrations: [
      new BrowserTracing({
        tracingOrigins: [/api\..*gr4vy\.app/, /^\//],
      }),
    ],
    release: process.env.REACT_APP_RELEASE,
    environment: (() => {
      const host = document.location.host.split('.')

      if (host.length === 3) {
        return `${host[0]}-production`
      } else if (host.length === 4) {
        return `${host[1]}-sandbox`
      }

      return host[0]
    })(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    ignoreErrors: ['ChunkLoadError'],
  })

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
