import { useQuery } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { listPaymentServices } from 'connections/services'
import { PAYMENT_SERVICES } from 'shared/constants'
import { apiBaseUrl } from 'shared/helpers/api-url'
import { useFilters, Filters, usePaymentMethodDefinitions } from 'shared/hooks'
import TransactionColumnFilter, {
  FilterKey,
  PaymentMethodItemProps,
} from 'transactions/components/TransactionColumnFilter/TransactionColumnFilter'
import { giftCardIconUrl } from 'transactions/constants/giftCards'
import { FIVE_MINUTES } from 'transactions/constants/time'

type TransactionPaymentMethodFilterProps = {
  label: string
}

interface TransactionFilters extends Filters {
  method?: string | string[]
  cursor?: string
  hasGiftCardRedemptions?: boolean
}

const TransactionPaymentMethodFilter = ({
  label,
}: TransactionPaymentMethodFilterProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const paymentMethodDefinitions =
    usePaymentMethodDefinitions(merchantAccountId)
  const [filters, setFilters] = useFilters<TransactionFilters>()

  const paymentServices = useQuery({
    queryKey: [PAYMENT_SERVICES, merchantAccountId],
    queryFn: () => listPaymentServices({ limit: 100 }),
    staleTime: FIVE_MINUTES,
  })

  const uniquePaymentMethods = useMemo(() => {
    const uniquePaymentMethods = new Set(['gift_card:true'])
    const items = paymentServices?.data?.items ?? []
    const digitalWallets: PaymentMethodItemProps[] = [
      {
        active: true,
        displayName: 'Apple Pay',
        iconUrl: `${apiBaseUrl}/assets/icons/payment-method-definitions/applepay.svg`,
        id: 'applepay',
        method: 'applepay',
      },
      {
        active: true,
        displayName: 'Google Pay',
        iconUrl: `${apiBaseUrl}/assets/icons/payment-method-definitions/googlepay.svg`,
        id: 'googlepay',
        method: ['googlepay', 'googlepay_pan_only'],
      },
      {
        active: true,
        displayName: 'Click to Pay',
        iconUrl: `${apiBaseUrl}/assets/icons/payment-method-definitions/click-to-pay.svg`,
        id: 'click-to-pay',
        method: 'click-to-pay',
      },
      {
        active: true,
        displayName: 'Network tokens',
        iconUrl: `${apiBaseUrl}/assets/icons/payment-method-definitions/network-token.svg`,
        id: 'network-token',
        method: 'network-token',
      },
    ]
    const giftCards: PaymentMethodItemProps[] = [
      {
        active: true,
        displayName: 'Gift card',
        iconUrl: giftCardIconUrl,
        id: 'gift_card',
        method: undefined,
      },
    ]

    return items
      .reduce((acc: PaymentMethodItemProps[], { id, active, method }) => {
        if (uniquePaymentMethods.has(`${method}:${active}`)) {
          return acc
        }

        uniquePaymentMethods.add(`${method}:${active}`)
        return [
          ...acc,
          {
            id,
            method,
            active,
            displayName:
              paymentMethodDefinitions[method]?.longDisplayName ?? '',
            iconUrl: paymentMethodDefinitions[method]?.iconUrl,
          },
        ]
      }, [])
      .filter(
        (paymentMethod) =>
          !(
            !paymentMethod?.active &&
            uniquePaymentMethods.has(`${paymentMethod?.method}:${true}`)
          )
      )
      .concat(
        items.some(({ method }) => method === 'card') ? digitalWallets : []
      )
      .concat(giftCards)
      .sort((a, b) =>
        a.displayName.localeCompare(b.displayName, undefined, {
          sensitivity: 'base',
        })
      )
  }, [paymentServices?.data?.items, paymentMethodDefinitions])

  const handleToggleFilter = async (paymentMethod: PaymentMethodItemProps) => {
    setFilters(
      paymentMethod?.id === 'gift_card'
        ? {
            ...filters,
            method: undefined,
            hasGiftCardRedemptions: isEqual(
              (filters as TransactionFilters)?.hasGiftCardRedemptions,
              true
            )
              ? undefined
              : true,
          }
        : {
            ...filters,
            hasGiftCardRedemptions: undefined,
            method: isEqual(
              (filters as TransactionFilters)?.method,
              paymentMethod?.method
            )
              ? undefined
              : paymentMethod?.method,
          }
    )
  }

  const filter = useMemo(
    () => ({
      key: filters?.hasGiftCardRedemptions
        ? FilterKey.GiftCardRedemption
        : FilterKey.PaymentMethod,
      value: filters?.hasGiftCardRedemptions ? 'true' : filters?.method,
    }),
    [filters]
  )

  return (
    <TransactionColumnFilter
      data={uniquePaymentMethods}
      label={label}
      isLoading={paymentServices.isLoading}
      toggleLabel="Include inactive"
      onToggleFilter={handleToggleFilter}
      filter={filter}
    />
  )
}

export default TransactionPaymentMethodFilter
