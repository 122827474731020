import { Grid, GridItem, Heading, Stack } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { ConnectionCard } from 'connections/components/ConnectionCard/ConnectionCard'
import {
  ConfiguredGroup as ConfiguredGroupDef,
  connectionDefinitionCategoryNames,
} from 'connections/constants'
import { pathTo } from 'shared/paths/connections'

export const ConfiguredGroup = ({
  configuredGroup,
}: {
  configuredGroup: ConfiguredGroupDef
}) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  if ((configuredGroup.count ?? 0) === 0) {
    return null
  }

  return (
    <Stack gap={32}>
      <Heading>{configuredGroup.name}</Heading>
      <Grid>
        {configuredGroup.connections
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(
            ({
              id,
              name,
              active,
              definition: {
                id: definitionId,
                name: definitionName,
                group,
                iconUrl,
                provider,
                category,
              },
            }) => (
              <GridItem key={id} gridColumn="span 3">
                <ConnectionCard
                  url={pathTo.editConnectionSelector(
                    merchantAccountId,
                    group,
                    id,
                    definitionId
                  )}
                >
                  <ConnectionCard.Banner iconUrl={iconUrl} tag={provider} />
                  <ConnectionCard.Content
                    title={name}
                    subTitle={`${definitionName} (${connectionDefinitionCategoryNames[category]})`}
                    status={active ? 'Active' : 'Inactive'}
                  />
                </ConnectionCard>
              </GridItem>
            )
          )}
      </Grid>
    </Stack>
  )
}
