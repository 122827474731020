import { Grid, GridItem, Stack, Button } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  MerchantCountryCodeInput,
  MerchantDisplayNameInput,
  MerchantNameInput,
  MerchantUrlInput,
} from 'connections/components/DigitalWalletForm'
import { RemoveConnectionButton } from 'connections/components/RemoveConnectionButton'
import { useDigitalWallet } from 'connections/hooks/use-digital-wallet'
import {
  DigitalWalletEditFormValues as FormValues,
  getDigitalWallet,
} from 'connections/services'
import { Copy } from 'shared/components/Copy'
import { HelpText, Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { FormPromptUnsavedChanges } from 'shared/components/FormPromptUnsavedChanges'
import { Loading } from 'shared/components/Loading'
import { PageLayout } from 'shared/components/PageLayout'
import { DIGITAL_WALLET } from 'shared/constants'
import { pathTo } from 'shared/paths/connections'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { showErrorMessage, showSuccessMessage } from 'shared/utils/showMessage'

export const ClickToPayOverview = ({ title }: { title: string }) => {
  const { id, merchantAccountId } = useParams() as {
    id: string
    merchantAccountId: string
  }
  const navigate = useNavigate()
  const hasWritePermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )
  const {
    data: digitalWallet,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [DIGITAL_WALLET, id, merchantAccountId],
    queryFn: () => getDigitalWallet(id),
    meta: {
      error: {
        redirect: true,
      },
    },
  })
  const { update, remove } = useDigitalWallet(merchantAccountId)
  const [form] = Form.useForm<FormValues>()
  const [initialValues, setInitialValues] =
    useState<Partial<FormValues> | null>()

  const handleSubmit = (values: FormValues) => {
    form.validateFields()
    setInitialValues(values)
    update.mutate(
      { id, values },
      {
        onSuccess: () => {
          showSuccessMessage(
            <>
              <b>Click to Pay</b> has been updated
            </>
          )
          navigate(pathTo.connectionsConfigured(merchantAccountId))
        },
      }
    )
  }

  const onFinishFailed = () => {
    showErrorMessage('Please check the form for any errors before continuing.')
  }

  useEffect(() => {
    setInitialValues({
      merchantName: digitalWallet?.merchantName || '',
      merchantDisplayName: digitalWallet?.merchantDisplayName || '',
      merchantCountryCode: digitalWallet?.merchantCountryCode || '',
      merchantUrl: digitalWallet?.merchantUrl || '',
    })
  }, [
    digitalWallet?.id,
    digitalWallet?.merchantName,
    digitalWallet?.merchantDisplayName,
    digitalWallet?.merchantCountryCode,
    digitalWallet?.merchantUrl,
  ])

  const handleCancel = (e: SyntheticEvent) => {
    e.preventDefault()
    navigate(pathTo.connectionsConfigured(merchantAccountId), { replace: true })
  }

  const onDelete = () => {
    setInitialValues(form.getFieldsValue())
    remove.mutate(id, {
      onSuccess: () => {
        navigate(pathTo.connectionsConfigured(merchantAccountId))
      },
    })
  }

  if (isLoading || !initialValues || !initialValues.merchantName) {
    return <Loading />
  }

  const breadcrumbs = [
    {
      title: 'Configured',
      url: pathTo.connectionsConfigured(merchantAccountId),
    },
    {
      title: 'View connection',
    },
  ]
  return (
    <PageLayout title={title} isLoading={!isSuccess} breadcrumbs={breadcrumbs}>
      <Form
        name="connection"
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        scrollToFirstError={{
          block: 'center',
        }}
        onFinishFailed={onFinishFailed}
        disabled={!hasWritePermission}
      >
        <Stack paddingTop={8} gap={40}>
          <Grid>
            <GridItem gridColumn="span 6">
              <Stack gap={24}>
                {digitalWallet?.fields?.digitalPaymentApplicationId && (
                  <FormItem
                    label={<Label>Digitial payment application ID</Label>}
                  >
                    <Copy
                      text={digitalWallet.fields.digitalPaymentApplicationId}
                    />
                  </FormItem>
                )}
                {digitalWallet?.fields?.digitalPaymentApplicationName && (
                  <FormItem
                    label={<Label>Digital payment application name</Label>}
                  >
                    <Copy
                      text={digitalWallet.fields.digitalPaymentApplicationName}
                    />
                  </FormItem>
                )}
                <MerchantNameInput
                  help={<HelpText>The legal name of your company.</HelpText>}
                />
                <MerchantDisplayNameInput
                  disabled
                  help={
                    <HelpText>
                      The name of your company presented to the buyer.
                    </HelpText>
                  }
                />
                <MerchantUrlInput disabled />
                <MerchantCountryCodeInput />
              </Stack>
            </GridItem>
          </Grid>
          {hasWritePermission ? (
            <Stack direction="row" gap={16}>
              <Button
                loading={update.isPending}
                loadingText="Saving connection"
                variant="primary"
              >
                Update details
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <RemoveConnectionButton onDelete={onDelete} />
            </Stack>
          ) : (
            <Stack direction="row" gap={16}>
              <Button variant="secondary" onClick={handleCancel}>
                Back to connections
              </Button>
            </Stack>
          )}
        </Stack>
        <FormPromptUnsavedChanges form={form} initialValues={initialValues} />
      </Form>
    </PageLayout>
  )
}
