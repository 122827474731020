import {
  Button,
  Box,
  Flex,
  Modal,
  Text,
  Icon,
  Stack,
  Alert,
} from '@gr4vy/poutine-react'
import { Form, Input } from 'antd'
import { omit } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { InputCurrency } from 'shared/components/InputCurrency'
import currencyFormat from 'shared/helpers/currency-format'
import { showErrorMessage } from 'shared/utils/showMessage'
import { PaymentMethodDescription } from 'transactions/components/PaymentMethodDescription'
import { PaymentMethodsSelect } from 'transactions/components/PaymentMethodsSelect'
import { getPaymentMethodByKey } from 'transactions/helpers/get-payment-method'
import { usePaymentMethodOptions } from 'transactions/hooks/use-payment-method-options'
import { useTransactionDetails } from 'transactions/hooks/use-transaction-details'
import { useTransactionPaymentMethod } from 'transactions/hooks/use-transaction-payment-methods'
import { TransactionRefundProps } from 'transactions/hooks/use-transaction-refund'
import { Transaction } from 'transactions/services'
import styles from './styles.module.scss'
const { TextArea } = Input

export const ERROR_REFUND_REQUIRED = 'Please provide a refund amount'
export const ERROR_REFUND_RANGE =
  'Amount must be larger than 0 and less than the remaining refundable amount.'

interface FormValues extends Omit<TransactionRefundProps, 'amount'> {
  amount: number
  reason?: string
}

interface ModalTransactionRefundProps {
  loading: boolean
  transaction: Transaction
  onRefund: ({ amount, targetType, targetId }: TransactionRefundProps) => void
  onClose: () => void
}

const ModalTransactionRefund = ({
  transaction,
  loading,
  onRefund,
  onClose,
}: ModalTransactionRefundProps) => {
  const { paymentMethodOptions, defaultValue } =
    usePaymentMethodOptions(transaction)
  const { isSinglePaymentMethod } = useTransactionPaymentMethod(transaction)
  const {
    amount: transactionAmount,
    refundedAmount: transactionRefundedAmount,
    capturedAmount: transactionCapturedAmount,
    currency,
  } = transaction
  const transactionRefundableAmount =
    (transactionCapturedAmount || transactionAmount) - transactionRefundedAmount
  const { partialRefundSupported } = useTransactionDetails(transaction)
  const [form] = Form.useForm()
  const refundAmount = Form.useWatch('amount', form)
  const targetId = Form.useWatch('targetId', form)

  const formatAmount = useCallback(
    (value: number) => currencyFormat(value, { currency }),
    [currency]
  )

  const paymentMethod = useMemo(
    () => getPaymentMethodByKey(targetId, transaction),
    [targetId, transaction]
  )

  const refundableAmount = useMemo(() => {
    if (!paymentMethod) {
      return 0
    }

    if (paymentMethod.type === 'payment-method') {
      return transactionRefundableAmount
    }

    return paymentMethod.amount - paymentMethod.refundedAmount
  }, [paymentMethod, transactionRefundableAmount])

  const isPartialRefund = refundAmount < refundableAmount

  const initialValues: FormValues = {
    amount: refundableAmount,
    targetType: paymentMethod?.type || 'payment-method',
    targetId: defaultValue,
  }

  useEffect(() => {
    form.setFieldValue('amount', refundableAmount)
  }, [refundableAmount, form])

  useEffect(() => {
    if (paymentMethod?.type) {
      form.setFieldValue('targetType', paymentMethod.type)
    }
  }, [paymentMethod, form])

  const onFinish = (values: FormValues) => {
    onRefund(
      values.targetType === 'payment-method'
        ? omit(values, ['targetId'])
        : values
    )
  }

  const onFinishFailed = () => {
    showErrorMessage('Please check the form for any errors before continuing.')
  }

  return (
    <Modal
      title="Refund transaction"
      description="Enter the amount you want to refund. You can refund a captured
        transaction multiple times up to the full refund amount. The initial
        amount displayed below is the remaining refundable amount."
      icon={
        <Icon name="arrow-undo-up-left" width="32" height="32" color="red60" />
      }
      open
      onOpenChange={(status) => {
        !status && onClose()
      }}
      closeBtn={false}
    >
      <Stack gap="none">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initialValues}
          requiredMark={false}
        >
          <Stack gap={16}>
            <FormItem
              name="targetId"
              label={<Label>Payment method</Label>}
              rules={[
                {
                  required: !isSinglePaymentMethod,
                  message: 'Select a payment method.',
                },
              ]}
            >
              {isSinglePaymentMethod ? (
                <PaymentMethodDescription
                  paymentMethod={transaction.paymentMethod}
                  giftCardRedemptions={transaction.giftCardRedemptions}
                />
              ) : (
                <PaymentMethodsSelect options={paymentMethodOptions} />
              )}
            </FormItem>
            <FormItem
              label={<Label>Refund amount</Label>}
              name="amount"
              required
              rules={[
                {
                  required: true,
                  message: ERROR_REFUND_REQUIRED,
                },
                {
                  type: 'number',
                  min: 1,
                  max: refundableAmount,
                  message: ERROR_REFUND_RANGE,
                },
              ]}
              className={styles.input}
            >
              <InputCurrency currency={currency} />
            </FormItem>
            <FormItem label={<Label>Reason (optional)</Label>} name="reason">
              <TextArea rows={3} maxLength={100} />
            </FormItem>
            <FormItem name="targetType" hidden>
              <Input type="hidden" />
            </FormItem>
          </Stack>
          <FormItem shouldUpdate className={styles.notificationField}>
            {({ getFieldsError }) => {
              if (
                !getFieldsError().some(({ errors }) => errors.length) &&
                isPartialRefund
              ) {
                return (
                  <Alert
                    variant={
                      isSinglePaymentMethod &&
                      !!transaction.paymentMethod &&
                      !partialRefundSupported
                        ? 'notice'
                        : 'information'
                    }
                    marginTop={24}
                  >
                    <Alert.Content>
                      <Alert.Title>
                        You entered a partial refund amount
                      </Alert.Title>
                      <Alert.Text>
                        {isSinglePaymentMethod &&
                        !!transaction.paymentMethod ? (
                          <>
                            {partialRefundSupported ? (
                              <Text as="span">
                                If you refund {formatAmount(refundAmount)}, the
                                remaining transaction amount will be{' '}
                                {formatAmount(refundableAmount - refundAmount)}.
                              </Text>
                            ) : (
                              <Text as="span">
                                The connection used for this transaction
                                doesn&apos;t support partial refunds.
                              </Text>
                            )}
                          </>
                        ) : (
                          <Text as="span">
                            If you refund {formatAmount(refundAmount)}, the
                            remaining transaction amount will be{' '}
                            {formatAmount(refundableAmount - refundAmount)}.
                          </Text>
                        )}
                      </Alert.Text>
                    </Alert.Content>
                  </Alert>
                )
              }
              return null
            }}
          </FormItem>
        </Form>
      </Stack>
      <Box display="inline-flex" gap={4}>
        <Text as="span" fontWeight="bold">
          Warning:
        </Text>
        <Text as="span">This action can not be undone.</Text>
      </Box>
      <Flex justifyContent="flex-end" gap={16}>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primaryNegative"
          onClick={form.submit}
          loading={loading}
          loadingText="Refunding"
          disabled={
            isPartialRefund &&
            !!transaction.paymentMethod &&
            !partialRefundSupported
          }
        >
          Refund
        </Button>
      </Flex>
    </Modal>
  )
}

export default ModalTransactionRefund
