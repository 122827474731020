import { Button, Stack, Text } from '@gr4vy/poutine-react'
import { useState, SyntheticEvent } from 'react'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'
import { ActiveAppleCertificateModal } from '../ActiveAppleCertificateModal'

export const RemoveApplePayButton = ({
  activeCertificateCount,
  pendingCertificateCount,
  expiredCertificateCount,
  onViewCertificates,
  onDelete,
  disabled = false,
}: {
  activeCertificateCount?: number
  pendingCertificateCount?: number
  expiredCertificateCount?: number
  onViewCertificates: () => void
  onDelete: () => void
  disabled?: boolean
}) => {
  const [removeModalVisible, setRemoveModalVisible] = useState(false)
  const activeAppleCertificates = !!activeCertificateCount
  const otherAppleCertificates =
    !activeAppleCertificates &&
    !!(pendingCertificateCount || expiredCertificateCount)

  return (
    <>
      <Button
        variant="tertiaryNegative"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          setRemoveModalVisible(true)
        }}
        disabled={disabled}
      >
        Delete connection
      </Button>
      {removeModalVisible && activeAppleCertificates && (
        <ActiveAppleCertificateModal
          onViewCertificates={() => {
            onViewCertificates()
            setRemoveModalVisible(false)
          }}
          onCancel={() => setRemoveModalVisible(false)}
        />
      )}
      {removeModalVisible && otherAppleCertificates && (
        <ModalRemove
          title="Are you sure you want to remove this Apple Pay connection?"
          onCancel={() => setRemoveModalVisible(false)}
          onOk={() => {
            onDelete()
            setRemoveModalVisible(false)
          }}
        >
          <Stack gap={24}>
            <Text>
              By performing this action the connection will no longer be
              available and we won&apos;t be able to process any Apple Pay
              transactions.
            </Text>
            <Text>
              This connection can later be added up to your configured list of
              available connections and start again processing transactions with
              Apple Pay.
            </Text>
          </Stack>
        </ModalRemove>
      )}
      {removeModalVisible &&
        !activeAppleCertificates &&
        !otherAppleCertificates && (
          <ModalRemove
            title="Delete connection"
            onCancel={() => setRemoveModalVisible(false)}
            onOk={() => {
              onDelete()
              setRemoveModalVisible(false)
            }}
          >
            You are about to delete this connection and this change is
            non-reversable. Are you sure you want to proceed?
          </ModalRemove>
        )}
    </>
  )
}
