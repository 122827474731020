import { Button } from '@gr4vy/poutine-react'
import { useState, SyntheticEvent } from 'react'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'

export const RemoveConnectionButton = ({
  onDelete,
  disabled = false,
}: {
  onDelete: () => void
  disabled?: boolean
}) => {
  const [removeModalVisible, setRemoveModalVisible] = useState(false)

  return (
    <>
      <Button
        variant="tertiaryNegative"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          setRemoveModalVisible(true)
        }}
        disabled={disabled}
      >
        Delete connection
      </Button>
      {removeModalVisible && (
        <ModalRemove
          title="Delete connection"
          onCancel={() => setRemoveModalVisible(false)}
          onOk={() => {
            onDelete()
            setRemoveModalVisible(false)
          }}
        >
          You are about to delete this connection and this change is
          non-reversable. Are you sure you want to proceed?
        </ModalRemove>
      )}
    </>
  )
}
