import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { GET_CURRENT_USER } from 'shared/constants'
import { LIST_MERCHANT_ACCOUNTS } from 'shared/constants/query-keys/merchants'
import { useCursor, useFilters } from 'shared/hooks'
import {
  addMerchantAccount,
  listMerchantAccounts,
} from 'shared/services/merchant-accounts'
import View, { Filters } from './MerchantsAccountsPage'

const MerchantAccountsPage = ({ title }: { title: string }) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }

  const [cursor] = useCursor()
  const [filters, setFilters] = useFilters<Filters>()
  const queryClient = useQueryClient()

  const page = useQuery({
    queryKey: [LIST_MERCHANT_ACCOUNTS, merchantAccountId, cursor, filters],
    queryFn: () => listMerchantAccounts({ cursor, ...filters }),
    meta: {
      error: {
        redirect: true,
      },
    },
  })

  const create = useMutation({
    mutationFn: addMerchantAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LIST_MERCHANT_ACCOUNTS] })
      queryClient.invalidateQueries({ queryKey: [GET_CURRENT_USER] })
    },
  })

  const updateFilter = (f: Filters) => {
    setFilters({ ...filters, ...f })
  }

  return (
    <View
      title={title}
      page={page}
      onFilter={updateFilter}
      filters={filters}
      create={create}
    />
  )
}

export default MerchantAccountsPage
