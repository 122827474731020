import client, { CollectionResponse } from 'shared/services/client'

export type VaultForwardConfig = {
  type: string
  id: string
  merchantAccountId: string
  definitionId: string
  displayName: string
  hosts: string[]
}

export type VaultForwardDefinition = {
  type: string
  id: string
  displayName: string
  hosts: string[]
}

export const listVaultForwardConfigs = async (
  merchantAccountId: string
): Promise<CollectionResponse<VaultForwardConfig>> => {
  const response = await client.get('/vault-forward/configs', {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId },
  })
  return response.data
}

export const listVaultForwardDefinitions = async (): Promise<
  CollectionResponse<VaultForwardDefinition>
> => {
  const response = await client.get('/vault-forward/definitions')
  return response.data
}

export const addVaultForwardConfig = async ({
  definitionId,
}: AddVaultForwardConfig): Promise<VaultForwardConfig> => {
  const response = await client.post('/vault-forward/configs', { definitionId })
  return response.data
}

export const removeVaultForwardConfig = async (id: string): Promise<void> => {
  return client.delete(`/vault-forward/configs/${id}`)
}

export type AddVaultForwardConfig = Pick<VaultForwardConfig, 'definitionId'>
