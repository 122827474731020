import { Divider, Dropdown, Heading, Icon, Stack } from '@gr4vy/poutine-react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/paths'
import {
  AccessLevel,
  Resource,
  useResourcePermissions,
} from 'shared/permissions'
import { Menu } from './Menu'

export const SettingsMenu = () => {
  const navigate = useNavigate()

  const [canAccessMerchants, canAccessUsers, canAccessIntegrations] =
    useResourcePermissions(
      [Resource.merchantAccounts, Resource.users, Resource.apiKeyPairs],
      AccessLevel.write
    )

  return (
    <Menu tooltipContent="Settings" trigger={<Icon name="settings" />}>
      <Stack direction="column" gap={8} padding={16} color="gray100">
        <Stack paddingX={8} direction="column">
          <Heading level={6} as="h6">
            Settings
          </Heading>
        </Stack>
        <Divider />
        <Dropdown.Item
          onSelect={() => navigate(pathTo.users)}
          disabled={!canAccessUsers}
        >
          Users
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={() => navigate(pathTo.apiKeys)}
          disabled={!canAccessIntegrations}
        >
          Integrations
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={() => navigate(pathTo.merchants)}
          disabled={!canAccessMerchants}
        >
          Merchants
        </Dropdown.Item>
      </Stack>
    </Menu>
  )
}
