import {
  ButtonLink,
  Flex,
  Description,
  Tooltip,
  Box,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { usePaymentServiceDefinitions } from 'connections'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import {
  DataTable,
  ColumnDef,
  TimeDate,
  DataTableProps,
} from 'shared/components/DataTable'
import { is } from 'shared/helpers/is'
import { pathTo } from 'shared/paths/transactions'
import { QueryResult } from 'shared/services/client'
import { PaymentMethodsTag } from 'transactions/components/PaymentMethodsTag'
import { TransactionAmount } from 'transactions/components/TransactionAmount'
import TransactionAmountFilter from 'transactions/components/TransactionAmountFilter'
import TransactionConnectionFilter from 'transactions/components/TransactionConnectionFilter'
import TransactionDateTimeFilter from 'transactions/components/TransactionDateTimeFilter'
import TransactionPaymentMethodFilter from 'transactions/components/TransactionPaymentMethodFilter'
import { TransactionStatus } from 'transactions/components/TransactionStatus'
import TransactionStatusFilter from 'transactions/components/TransactionStatusFilter'
import { TransactionTableColumnId } from 'transactions/constants/table'
import { TransactionSummary } from 'transactions/services'
import { PaymentMethodDescription } from '../PaymentMethodDescription'
import styles from './TransactionTable.module.scss'

export type TransactionTableProps = Pick<
  DataTableProps<TransactionSummary>,
  'data' | 'pagination' | 'loading' | 'columns'
> & { page: QueryResult<TransactionSummary> }

export const transactionColumns: Array<ColumnDef<TransactionSummary>> = [
  {
    accessorKey: 'status',
    size: 192,
    cell: ({ row }) => {
      return <TransactionStatus transaction={row.original} />
    },
    header: () => (
      <Box className={styles.filter}>
        <TransactionStatusFilter label="Status" />
      </Box>
    ),
  },
  {
    accessorKey: 'amount',
    size: 128,
    cell: ({ row }) => {
      return <TransactionAmount transaction={row.original} />
    },
    header: () => (
      <Box className={[styles.filter, styles.amount]}>
        <TransactionAmountFilter />
      </Box>
    ),
  },
  {
    id: TransactionTableColumnId.PAYMENT_METHOD,
    accessorKey: TransactionTableColumnId.PAYMENT_METHOD,
    size: 272,
    cell: function PaymentMethodCell({ row }) {
      const giftCardRedemptions = row.original.giftCardRedemptions
      const paymentMethod = row.original.paymentMethod

      return (
        <Flex alignItems="center">
          <Box minWidth={0}>
            <PaymentMethodDescription
              paymentMethod={paymentMethod}
              giftCardRedemptions={giftCardRedemptions}
            />
          </Box>
          <PaymentMethodsTag
            paymentMethod={paymentMethod}
            giftCardRedemptions={giftCardRedemptions}
          />
        </Flex>
      )
    },
    header: () => (
      <Box className={styles.filter}>
        <TransactionPaymentMethodFilter label="Payment Method" />
      </Box>
    ),
  },
  {
    accessorKey: 'paymentService',
    id: TransactionTableColumnId.CONNECTION,
    size: 272,
    cell: function ConnectionCell({ getValue, row }) {
      const transaction = row.original
      const { merchantAccountId } = useParams()
      const paymentService = getValue<TransactionSummary['paymentService']>()
      const { paymentServiceDefinitions } = usePaymentServiceDefinitions()
      const { giftCardServiceDefinition } = useGiftCardServiceDefinition(
        merchantAccountId,
        transaction.giftCardService?.giftCardServiceDefinitionId,
        {
          enabled:
            !!transaction.giftCardService?.giftCardServiceDefinitionId &&
            !transaction.paymentMethod,
        }
      )
      const paymentServiceDefinition =
        paymentService?.paymentServiceDefinitionId
          ? paymentServiceDefinitions[paymentService.paymentServiceDefinitionId]
          : null

      const getDisplayName = () => {
        if (!transaction.paymentMethod) {
          return giftCardServiceDefinition?.displayName
        }

        return paymentService?.displayName &&
          !is.emptyString(paymentServiceDefinition?.displayName)
          ? paymentService.displayName
          : null
      }

      const displayName = getDisplayName()
      const iconUrl = transaction.paymentMethod
        ? paymentServiceDefinition?.iconUrl
        : giftCardServiceDefinition?.iconUrl

      return (
        <Description>
          <Description.Icon size={24} src={iconUrl} />
          {!!displayName && <Description.Text>{displayName}</Description.Text>}
        </Description>
      )
    },
    header: () => (
      <Box className={styles.filter}>
        <TransactionConnectionFilter label="Connection" />
      </Box>
    ),
  },
  {
    accessorKey: TransactionTableColumnId.BUYER,
    id: TransactionTableColumnId.BUYER,
    size: 160,
    cell: function BuyerCell({ row }) {
      const { merchantAccountId } = useParams() as {
        merchantAccountId: string
      }

      const transaction = row.original
      const displayName =
        transaction?.buyer?.displayName ||
        transaction?.buyer?.externalIdentifier

      return (
        <Description>
          {displayName && transaction?.id ? (
            <Description.Link
              href={pathTo.transaction(
                merchantAccountId,
                transaction.id,
                'buyer'
              )}
            >
              {displayName}
            </Description.Link>
          ) : displayName ? (
            <Description.Text>{displayName}</Description.Text>
          ) : (
            <></>
          )}
        </Description>
      )
    },
    header: 'Buyer',
  },
  {
    accessorKey: 'createdAt',
    size: 128,
    cell: function DatetimeCell({ getValue }) {
      const createdAt = getValue<TransactionSummary['createdAt']>()
      return <TimeDate value={createdAt} />
    },
    header: () => (
      <Box className={styles.filter}>
        <TransactionDateTimeFilter label="Date/Time" />
      </Box>
    ),
  },
  {
    id: TransactionTableColumnId.ACTIONS,
    header: '',
    size: 64,
    cell: function ActionsCell({ row }) {
      const { merchantAccountId } = useParams() as { merchantAccountId: string }
      const transaction = row.original
      return transaction ? (
        <Flex justifyContent="flex-end">
          <Tooltip content="Go to transaction">
            <ButtonLink
              variant="tertiary"
              size="small"
              href={pathTo.transaction(merchantAccountId, transaction?.id)}
            />
          </Tooltip>
        </Flex>
      ) : null
    },
  },
]

const TransactionTable = ({
  page,
  pagination,
  columns,
}: TransactionTableProps) => {
  const { data, isLoading: loading } = page

  return (
    <DataTable
      data={data}
      pagination={pagination}
      columns={columns ?? transactionColumns}
      loading={loading}
    />
  )
}

export default TransactionTable
