import { generatePath } from 'react-router-dom'
export const TRANSACTIONS_PATH = '/merchants/:merchantAccountId/transactions'
export const TRANSACTION_PATH = `${TRANSACTIONS_PATH}/:transactionID`
export const TRANSACTION_OVERVIEW_PATH = `${TRANSACTION_PATH}/overview`
export const RELATED_TRANSACTIONS_PATH = `${TRANSACTION_PATH}/related-transactions`

export const pathTo = {
  transactions: (merchantAccountId: string) =>
    generatePath(TRANSACTIONS_PATH, { merchantAccountId }),
  transaction: (
    merchantAccountId: string,
    transactionID: string,
    anchor?: string
  ) =>
    `${generatePath(TRANSACTION_OVERVIEW_PATH, {
      merchantAccountId,
      transactionID,
    })}${anchor ? `#${anchor}` : ''}`,
  relatedTransactions: (merchantAccountId: string, transactionID: string) =>
    generatePath(RELATED_TRANSACTIONS_PATH, {
      merchantAccountId,
      transactionID,
    }),
}
