import { InputNumber, InputNumberProps } from 'antd'
import { useRef } from 'react'
import currencyFormat from 'shared/helpers/currency-format'
import currencyParse from 'shared/helpers/currency-parse'
import { getCurrentLocale } from 'shared/helpers/locale'
import { parseDigits } from 'shared/utils/currency'
import styles from './InputCurrency.module.scss'

interface InputCurrencyProps extends InputNumberProps {
  currency?: string
  locale?: string
}

export const InputCurrency = ({
  currency = 'USD',
  locale = getCurrentLocale(),
  onBlur,
  ...rest
}: InputCurrencyProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <InputNumber
      ref={inputRef}
      prefix={currency}
      formatter={(value, { userTyping, input }) => {
        const digits = parseDigits(
          inputRef?.current?.value || input,
          currency,
          locale
        )
        const onBlurOptions = userTyping
          ? {
              digits,
              minimumFractionDigits: 0,
            }
          : {}
        return currencyFormat(
          value,
          {
            style: 'decimal',
            currency,
            ...onBlurOptions,
          },
          locale
        )
      }}
      parser={(value) => currencyParse(value, currency, locale)}
      className={styles.input}
      onBlur={(e) =>
        onBlur?.(currencyParse(e.currentTarget.value, currency, locale))
      }
      {...rest}
    />
  )
}
