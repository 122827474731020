import {
  Box,
  Description,
  Text,
  Modal,
  Dropdown,
  Icon,
  TextLink,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { pathTo, usePaymentServiceDefinitions } from 'connections'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import {
  DataTable,
  ColumnDef,
  DataTableProps,
} from 'shared/components/DataTable'
import currencyFormat from 'shared/helpers/currency-format'
import { is } from 'shared/helpers/is'
import { Filters, useFilters } from 'shared/hooks'
import { pathTo as pathToGiftCards } from 'shared/paths/gift-cards'
import { pathTo as pathToPaymentMethods } from 'shared/paths/payment-methods'
import {
  AccessLevel,
  Resource,
  RestrictAccess,
  useResourcePermission,
} from 'shared/permissions'
import { GiftCardStatus } from 'transactions/components/GiftCardStatus'
import {
  ModalTransactionCapture,
  ModalTransactionRefund,
  ModalTransactionVoid,
} from 'transactions/components/Modal'
import { PaymentMethodDescription } from 'transactions/components/PaymentMethodDescription'
import { TransactionStatus } from 'transactions/components/TransactionStatus'
import { getPaymentMethodKey } from 'transactions/helpers/get-payment-method'
import { useTransactionCapture } from 'transactions/hooks/use-transaction-capture'
import { useTransactionDetails } from 'transactions/hooks/use-transaction-details'
import { useTransactionRefund } from 'transactions/hooks/use-transaction-refund'
import { useTransactionVoid } from 'transactions/hooks/use-transaction-void'
import {
  GiftCardRedemption,
  Transaction,
  PaymentMethod,
} from 'transactions/services'
import styles from './PaymentMethodsTable.module.scss'

const { useModal } = Modal

export interface PaymentMethodsTableProps extends DataTableProps<Transaction> {
  transaction: Transaction
  paymentMethod?: PaymentMethod
}

export type PaymentMethodItem = PaymentMethod | GiftCardRedemption

const PaymentMethodsTable = ({
  transaction,
  paymentMethod,
}: PaymentMethodsTableProps) => {
  const { merchantAccountId } = useParams()
  const { isModalOpen, openModal, closeModal } = useModal()
  const [, setFilters] = useFilters<Filters & { paymentMethodKey: string }>()

  const { onCapture, capturing } = useTransactionCapture(transaction, () => {
    closeModal('capture')
  })
  const { onVoid, voiding } = useTransactionVoid(transaction, () => {
    closeModal('void')
  })
  const { onRefund, refunding } = useTransactionRefund(transaction, () => {
    closeModal('refund')
  })

  const columns: Array<ColumnDef<PaymentMethodItem>> = [
    {
      header: 'Method',
      size: 260,
      cell: function Number({ row }) {
        const transactionPaymentMethod =
          row.original.type === 'payment-method' ? row.original : null
        const giftCardRedemptions =
          row.original.type === 'gift-card-redemption' ? [row.original] : []

        const getPaymentUrl = () => {
          if (!!paymentMethod?.id && !!merchantAccountId) {
            return pathToPaymentMethods.paymentMethodId(
              merchantAccountId,
              paymentMethod.id
            )
          }

          if (!!giftCardRedemptions[0]?.giftCard.id && !!merchantAccountId) {
            return pathToGiftCards.giftCardId(
              merchantAccountId,
              giftCardRedemptions[0].giftCard.id
            )
          }

          return null
        }

        const paymentUrl = getPaymentUrl()

        return paymentUrl ? (
          <TextLink href={paymentUrl}>
            <PaymentMethodDescription
              paymentMethod={transactionPaymentMethod}
              giftCardRedemptions={giftCardRedemptions}
            />
          </TextLink>
        ) : (
          <PaymentMethodDescription
            paymentMethod={transactionPaymentMethod}
            giftCardRedemptions={giftCardRedemptions}
          />
        )
      },
    },
    {
      header: 'Connection',
      size: 249,
      cell: function Connection({ row }) {
        const isGiftCard = row.original.type === 'gift-card-redemption'
        const { merchantAccountId } = useParams() as {
          merchantAccountId: string
        }
        const { paymentServiceDefinitions } = usePaymentServiceDefinitions()
        const { giftCardServiceDefinition } = useGiftCardServiceDefinition(
          merchantAccountId,
          transaction.giftCardService?.giftCardServiceDefinitionId,
          {
            enabled:
              isGiftCard &&
              !!transaction.giftCardService?.giftCardServiceDefinitionId,
          }
        )
        const paymentServiceDefinition = transaction.paymentService
          ?.paymentServiceDefinitionId
          ? paymentServiceDefinitions[
              transaction.paymentService.paymentServiceDefinitionId
            ]
          : null

        const getDisplayName = () => {
          if (!transaction.paymentMethod || isGiftCard) {
            return giftCardServiceDefinition?.displayName
          }

          return transaction.paymentService?.displayName &&
            !is.emptyString(paymentServiceDefinition?.displayName)
            ? transaction.paymentService.displayName
            : null
        }

        const hasPermission = useResourcePermission(
          Resource.connections,
          AccessLevel.read
        )

        const displayName = getDisplayName()
        const paymentServiceId = transaction.paymentService?.id
        const giftCardServiceId = transaction.giftCardService?.id

        const iconUrl =
          transaction.paymentMethod && !isGiftCard
            ? paymentServiceDefinition?.iconUrl
            : giftCardServiceDefinition?.iconUrl

        if (row.original.type === 'gift-card-redemption') {
          return (
            <Description>
              <Description.Icon size={24} src={iconUrl} />
              {!!displayName && !!giftCardServiceId && hasPermission ? (
                <Description.Link
                  href={pathTo.editConnectionGiftCardService(
                    merchantAccountId,
                    giftCardServiceId
                  )}
                >
                  {displayName}
                </Description.Link>
              ) : (
                !!displayName && (
                  <Description.Text>{displayName}</Description.Text>
                )
              )}
              {!!row.original.giftCardServiceRedemptionId && (
                <Description.SubText>
                  {row.original.giftCardServiceRedemptionId}
                </Description.SubText>
              )}
            </Description>
          )
        }

        return (
          <Description>
            <Description.Icon size={24} src={iconUrl} />
            {!!displayName && !!paymentServiceId && hasPermission ? (
              <Description.Link
                href={pathTo.editConnectionPaymentService(
                  merchantAccountId,
                  paymentServiceId
                )}
              >
                {displayName}
              </Description.Link>
            ) : (
              !!displayName && (
                <Description.Text>{displayName}</Description.Text>
              )
            )}

            {!!transaction.paymentServiceTransactionId && (
              <Description.SubText>
                {transaction.paymentServiceTransactionId}
              </Description.SubText>
            )}
          </Description>
        )
      },
    },
    {
      header: () => <Text className={styles.amount}>Amount</Text>,
      accessorKey: 'amount',
      size: 144,
      cell: ({ row }) => {
        const amount =
          row.original.type === 'payment-method'
            ? transaction.authorizedAmount
            : row.original.amount
        const formattedAmount = currencyFormat(amount, {
          currency: transaction?.currency,
          style: 'decimal',
        })

        return (
          <Box marginRight={16}>
            <Description align="flex-end">
              {!!amount && (
                <Description.Text
                  style={{
                    fontVariantNumeric: 'tabular-nums',
                  }}
                >
                  {formattedAmount}
                </Description.Text>
              )}
              {!!amount && (
                <Description.SubText>
                  {transaction.currency}
                </Description.SubText>
              )}
            </Description>
          </Box>
        )
      },
    },
    {
      header: () => <Text className={styles.refundedAmount}>Captured</Text>,
      accessorKey: 'capturedAmount',
      size: 120,
      cell: ({ row }) => {
        const capturedAmount =
          row.original.type === 'payment-method'
            ? transaction.capturedAmount
            : row.original.amount
        const formattedCapturedAmount = currencyFormat(capturedAmount, {
          currency: transaction?.currency,
          style: 'decimal',
        })

        return (
          <Box marginRight={16}>
            <Description align="flex-end">
              {!!capturedAmount && (
                <Description.Text
                  style={{
                    fontVariantNumeric: 'tabular-nums',
                  }}
                >
                  {formattedCapturedAmount}
                </Description.Text>
              )}
              {!!capturedAmount && (
                <Description.SubText>
                  {transaction.currency}
                </Description.SubText>
              )}
            </Description>
          </Box>
        )
      },
    },
    {
      header: () => <Text className={styles.refundedAmount}>Refunded</Text>,
      accessorKey: 'refundedAmount',
      size: 120,
      cell: ({ row }) => {
        const refundedAmount =
          row.original.type === 'payment-method'
            ? transaction.refundedAmount
            : row.original.refundedAmount
        const formattedRefundedAmount = currencyFormat(refundedAmount, {
          currency: transaction?.currency,
          style: 'decimal',
        })

        return (
          <Box marginRight={16}>
            <Description align="flex-end">
              {!!refundedAmount && (
                <Description.Text
                  style={{
                    fontVariantNumeric: 'tabular-nums',
                  }}
                >
                  {formattedRefundedAmount}
                </Description.Text>
              )}
              {!!refundedAmount && (
                <Description.SubText>
                  {transaction.currency}
                </Description.SubText>
              )}
            </Description>
          </Box>
        )
      },
    },
    {
      header: 'Status',
      size: 160,
      cell: ({ row }) => {
        if (row.original.type === 'payment-method') {
          return <TransactionStatus transaction={transaction} skipGiftCards />
        }

        return <GiftCardStatus giftCardRedemption={row.original} />
      },
    },
    {
      id: 'actions',
      header: '',
      size: 80,
      cell: function ActionsCell({ row }) {
        const {
          captureSupported,
          captureEnabled,
          refundSupported,
          refundEnabled,
          voidSupported,
          voidEnabled,
        } = useTransactionDetails(transaction)

        const isRefundEnabled =
          row.original.type === 'payment-method'
            ? refundSupported && refundEnabled
            : row.original.status === 'succeeded' &&
              row.original.amount - row.original.refundedAmount > 0

        const isCaptureEnabled =
          transaction.paymentMethod && captureSupported && captureEnabled

        const onTransactionRefund = () => {
          setFilters({
            paymentMethodKey: getPaymentMethodKey(row.original),
          })
          openModal('refund')
        }

        return (
          <RestrictAccess
            resource={Resource.transactions}
            accessLevel={AccessLevel.write}
          >
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button variant="tertiary" size="small">
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                {row.original.type === 'payment-method' && (
                  <Dropdown.Item
                    opensInModal
                    disabled={!isCaptureEnabled}
                    onSelect={() => openModal('capture')}
                  >
                    Capture
                  </Dropdown.Item>
                )}

                <Dropdown.Item
                  opensInModal
                  disabled={!isRefundEnabled}
                  onSelect={onTransactionRefund}
                >
                  Refund
                </Dropdown.Item>
                {row.original.type === 'payment-method' && (
                  <Dropdown.Item
                    opensInModal
                    disabled={!voidSupported || !voidEnabled}
                    onSelect={() => openModal('void')}
                  >
                    Void
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          </RestrictAccess>
        )
      },
    },
  ]

  const paymentMethodItems = [
    ...(transaction.paymentMethod ? [transaction.paymentMethod] : []),
    ...(transaction.giftCardRedemptions || []),
  ]

  return (
    <>
      {isModalOpen('refund') && (
        <ModalTransactionRefund
          transaction={transaction}
          loading={refunding}
          onRefund={onRefund}
          onClose={() => closeModal('refund')}
        />
      )}
      {isModalOpen('capture') && (
        <ModalTransactionCapture
          transaction={transaction}
          loading={capturing}
          onCapture={onCapture}
          onClose={() => closeModal('capture')}
        />
      )}
      {isModalOpen('void') && (
        <ModalTransactionVoid
          transaction={transaction}
          loading={voiding}
          onVoid={onVoid}
          onClose={() => closeModal('void')}
        />
      )}
      <DataTable data={{ items: paymentMethodItems }} columns={columns} />
    </>
  )
}

export default PaymentMethodsTable
