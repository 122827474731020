import qs from 'qs'
import { GiftCardService } from 'connections/services'
import { RuleOutcome } from 'flows/constants'
import { Flow } from 'shared/constants'
import client, { CollectionResponse } from 'shared/services/client'
import {
  TransactionFilters,
  TransactionStatus,
} from 'shared/services/transactions'

export interface Address {
  city: string
  country: string
  houseNumberOrName: string
  line1: string
  line2?: string
  organization?: string
  postalCode: string
  state: string
}

export interface ContactDetails {
  address?: Address | null
  emailAddress?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
}

export interface BuyerTaxId {
  value: string
  kind: string
}

export interface BuyerBillingDetails extends ContactDetails {
  taxId?: BuyerTaxId
  type: 'billing-details'
}

export interface Buyer {
  type: 'buyer'
  id: string
  externalIdentifier?: string
  displayName?: string
  billingDetails?: BuyerBillingDetails
}

export interface ShippingDetails extends ContactDetails {
  address?: Address | null
  buyerId: string
  emailAddress?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  type: 'shipping-details'
}

export type PaymentMethodMethod =
  | 'card'
  | 'paypal'
  | 'klarna'
  | 'banked'
  | 'applepay'
  | 'googlepay'
  | 'googlepay_pan_only'
  | 'network-token'

export interface PaymentMethodDetails {
  bin?: string
  cardType?: string
}

export interface PaymentMethod {
  type: 'payment-method'
  id?: string
  method: PaymentMethodMethod
  externalIdentifier?: string
  label?: string
  scheme?: string
  expirationDate?: string
  approvalUrl?: string
  details?: PaymentMethodDetails
  country: string | null
}

export type AVSStatusCode =
  | 'match'
  | 'no_match'
  | 'partial_match_address'
  | 'partial_match_postcode'
  | 'unavailable'

export type CVVStatusCode =
  | 'match'
  | 'no_match'
  | 'unavailable'
  | 'not_provided'

export interface TransactionPaymentService {
  id: string
  type: 'payment-service'
  paymentServiceDefinitionId: string
  method: string
  displayName: string
}

export interface CartItem {
  name: string
  quantity: number
  unitAmount: number
  categories: string[] | null
  discountAmount?: number
  externalIdentifier: string | null
  imageUrl: string | null
  productType: string | null
  productUrl: string | null
  sku: string | null
  taxAmount: number | null
  currency: string | null
}

export interface ThreeDSecure {
  version: string
  status: 'complete' | 'cancelled' | 'declined' | 'error' | 'setup_error'
  method: 'challenge' | 'frictionless'
  responseData: {
    authenticationResponse: string
    cavv: string
    cavvAlgorithm?: string
    directoryResponse: string
    directoryTransactionId?: string
    eci: string
    version: string
    xid?: string
    transactionReason?: string
  } | null
  errorData: {
    description: string | null
    detail: string | null
    code: string | null
    component: string | null
  } | null
}

export interface GiftCard {
  type: 'gift-card'
  id: string | null
  bin: string
  subBin: string
  last4: string
}

export interface GiftCardRedemption {
  type: 'gift-card-redemption'
  id: string
  status: 'created' | 'succeeded' | 'failed' | 'skipped'
  amount: number
  refundedAmount: number
  giftCardServiceRedemptionId: string | null
  errorCode: string | null
  giftCard: GiftCard
}

export interface TransactionSummary {
  id: string
  type: 'transaction'
  status: TransactionStatus
  errorCode?: string
  amount: number
  authorizedAmount: number
  capturedAmount: number
  refundedAmount: number
  currency: string
  paymentMethod: PaymentMethod | null
  buyer?: Buyer
  createdAt: string
  externalIdentifier: string
  updatedAt: string
  country?: string
  paymentService: TransactionPaymentService | null
  cartItems: CartItem[]
  metadata?: { [key: string]: string } | null
  threeDSecure?: ThreeDSecure | null
  merchantInitiated?: boolean
  paymentSource?:
    | 'ecommerce'
    | 'moto'
    | 'recurring'
    | 'installment'
    | 'card_on_file'
  isSubsequentPayment?: boolean
  rawResponseDescription?: string
  rawResponseCode?: string
  paymentMethodId?: string
  pendingReview?: boolean
  checkoutSessionId?: string
  giftCardRedemptions: GiftCardRedemption[]
  giftCardService: Pick<
    GiftCardService,
    'id' | 'giftCardServiceDefinitionId' | 'displayName'
  > | null
}

export interface Transaction extends TransactionSummary {
  avsResponseCode?: AVSStatusCode
  cvvResponseCode?: CVVStatusCode
  authResponseCode?: AVSStatusCode
  rawResponseCode?: string
  rawResponseDescription?: string
  paymentServiceTransactionId?: string
  schemeTransactionId?: string
  intent: 'authorize' | 'capture'
  authorizedAt?: string
  capturedAt?: string
  voidedAt?: string
  buyerApprovalTimedoutAt?: string
  errorCode?: string
  shippingDetails?: ShippingDetails
  reconciliationId?: string
}

export type RefundStatus =
  | 'processing'
  | 'succeeded'
  | 'declined'
  | 'failed'
  | 'voided'

interface DefaultFilters {
  cursor: string
  limit: number
}
export type RefundFilters = DefaultFilters

export interface Refund {
  type: 'refund'
  id: string
  transactionId: string
  paymentServiceRefundId: string
  status: RefundStatus
  currency: string
  amount: number
  createdAt: string
  updatedAt: string
  targetType: 'payment-method' | 'gift-card-redemption'
  targetId?: string
  reason: string | null
}

export type TransactionFlowId =
  | 'decline-early'
  | 'route-transaction'
  | 'skip-3ds'

export interface TransactionFlow {
  type: 'action'
  ruleId: string
  id: TransactionFlowId
  flow:
    | Flow.checkout
    | Flow.cardTransactions
    | Flow.redirectTransactions
    | Flow.nonCardTransactions
  createdAt: string
  outcome?: RuleOutcome
}

export interface RefundRequest {
  id: string
  targetType: 'payment-method' | 'gift-card-redemption'
  amount?: number
  reason?: string
  targetId?: string
}

export interface Capture {
  type: 'transaction'
  id: string
  status: 'capture_pending' | 'capture_succeeded'
  amount: number
}

export interface TransactionEvent {
  type: 'transaction-event'
  id: string
  name: string
  createdAt: string
  context: Record<string, any>
  provider?: string
}

export type TransactionEventFilters = DefaultFilters

export const listTransactions = async (
  params: Partial<TransactionFilters> = {}
): Promise<CollectionResponse<TransactionSummary>> => {
  const response = await client.get('/transactions', {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  })
  return response.data
}

export const getTransaction = async (
  id: string,
  params: Partial<TransactionFilters> = {}
): Promise<Transaction> => {
  const response = await client.get(`/transactions/${id}`, { params })
  return response.data
}

export const listRefunds = async (
  transactionId: string,
  params: Partial<RefundFilters>
): Promise<CollectionResponse<Refund>> => {
  const response = await client.get(`/transactions/${transactionId}/refunds`, {
    params,
  })

  return response.data
}

export const listFlowActions = async (
  transactionId: string
): Promise<CollectionResponse<TransactionFlow>> => {
  const response = await client.get(`/transactions/${transactionId}/actions`)

  return response.data
}

export const captureTransaction = async (
  id: string,
  values: Partial<Pick<Capture, 'amount'>>
): Promise<Capture> => {
  const response = await client.post(`/transactions/${id}/capture`, values)
  return response.data
}

export const refundTransaction = async ({
  id,
  ...params
}: RefundRequest): Promise<Refund> => {
  const response = await client.post(`/transactions/${id}/refunds`, params)
  return response.data
}

export const voidTransaction = async (id: string): Promise<Transaction> => {
  const response = await client.post(`/transactions/${id}/void`)
  return response.data
}

export const listTransactionEvents = async (
  transactionId: string
): Promise<CollectionResponse<TransactionEvent>> => {
  const response = await client.get(`/transactions/${transactionId}/events`)
  return response.data
}

export const getPaymentMethod = async (id: string): Promise<PaymentMethod> => {
  const response = await client.get(`/payment-methods/${id}`)
  return response.data
}
