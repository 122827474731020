import { currencies as allowedCurrencies } from 'shared/constants'
import isoCurrencies from 'shared/constants/currencies-iso-4217.json'

interface Currency {
  code: string
  number: string
  digits: number
  currency: string
}

const allCurrencies = [
  ...isoCurrencies,
  { code: 'VEF', currency: 'Bolívar Fuerte', digits: 2, number: '937' },
].sort((a, b) => (a.code > b.code ? 1 : -1))

export const currencies: Currency[] = allCurrencies.filter(({ code }) =>
  allowedCurrencies.includes(code)
)

export const getCurrencyByCode = (currencyCode: string): Currency | undefined =>
  allCurrencies.find(({ code }) => code === currencyCode)
