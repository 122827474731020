import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getCurrencyByCode } from 'shared/helpers/currency'
import { Filters } from 'shared/hooks'
import { Types } from 'transactions/components/TransactionAmountFilter/OperatorSelect'

export const INITIAL_RANGE = { min: undefined, max: undefined }
export const INITIAL_OPERATORS = {
  amountEq: undefined,
  amountLte: undefined,
  amountGte: undefined,
}

export interface TransactionFilters extends Filters {
  amountEq?: number
  amountGte?: number
  amountLte?: number
  currency?: string[]
}

export const useTransactionAmount = () => {
  const [searchParams] = useSearchParams()
  const [operator, setOperator] = useState<Types>(Types.ANY)
  const [currencies, setCurrencies] = useState<string[]>([])
  const [amount, setAmount] = useState<number | undefined>(undefined)
  const [amountRange, setAmountRange] =
    useState<Record<string, number | undefined>>(INITIAL_RANGE)

  const getFilterFromOperator = (type: Types) =>
    ({
      [Types.EQUAL_TO]: {
        amountEq: amount,
        amountLte: undefined,
        amountGte: undefined,
      },
      [Types.LESS_THAN_OR_EQUAL]: {
        amountEq: undefined,
        amountLte: amount,
        amountGte: undefined,
      },
      [Types.GREATER_THAN_OR_EQUAL]: {
        amountEq: undefined,
        amountLte: undefined,
        amountGte: amount,
      },
      [Types.BETWEEN]: {
        amountEq: undefined,
        amountLte: amountRange.max,
        amountGte: amountRange.min,
      },
      [Types.ANY]: {
        ...INITIAL_OPERATORS,
      },
    })[type as string]

  const operatorFromFilters = useMemo(() => {
    if (searchParams.get('amountEq')) {
      return Types.EQUAL_TO
    }

    if (searchParams.get('amountGte') && searchParams.get('amountLte')) {
      return Types.BETWEEN
    }

    if (searchParams.get('amountGte')) {
      return Types.GREATER_THAN_OR_EQUAL
    }

    if (searchParams.get('amountLte')) {
      return Types.LESS_THAN_OR_EQUAL
    }

    return undefined
  }, [searchParams])

  const isActive = useMemo(
    () =>
      searchParams.get('amountEq') ||
      searchParams.get('amountGte') ||
      searchParams.get('amountLte') ||
      searchParams.get('currency'),
    [searchParams]
  )

  const formatFilterAmount = useCallback(
    (amount: string | null) => {
      const currency =
        searchParams.get('currency') &&
        !searchParams.get('currency')?.includes(',')
          ? (searchParams.get('currency') as string)
          : 'USD'
      const { digits: currencyDigits = 2 } = getCurrencyByCode(currency) || {}

      return Number(amount ?? 0) / 10 ** currencyDigits
    },
    [searchParams]
  )

  const getOperatorAndAmountFromUrl = useCallback(() => {
    if (searchParams.get('currency')) {
      setCurrencies((searchParams.get('currency') as string).split(','))
    }

    if (operatorFromFilters) {
      setOperator(operatorFromFilters)
    }

    if (
      searchParams.get('currency') &&
      searchParams.get('currency')?.includes(',')
    ) {
      return
    }

    const greaterThanOrEqualFilter = formatFilterAmount(
      searchParams.get('amountGte')
    )
    const lessThanOrEqualFilter = formatFilterAmount(
      searchParams.get('amountLte')
    )
    const equalTo = formatFilterAmount(searchParams.get('amountEq'))

    if (
      operatorFromFilters === Types.BETWEEN &&
      greaterThanOrEqualFilter &&
      lessThanOrEqualFilter
    ) {
      setAmountRange((currAmountRange) => ({
        ...currAmountRange,
        ...(Number(searchParams.get('amountGte')) !== currAmountRange?.min && {
          min: greaterThanOrEqualFilter,
        }),
        ...(Number(searchParams.get('amountLte')) !== currAmountRange?.max && {
          max: lessThanOrEqualFilter,
        }),
      }))
    }

    if (
      (equalTo || greaterThanOrEqualFilter || lessThanOrEqualFilter) &&
      operatorFromFilters !== Types.BETWEEN
    ) {
      setAmount(equalTo || greaterThanOrEqualFilter || lessThanOrEqualFilter)
    }
  }, [formatFilterAmount, operatorFromFilters, searchParams])

  useEffect(() => {
    getOperatorAndAmountFromUrl()
  }, [getOperatorAndAmountFromUrl])

  return {
    operator,
    setOperator,
    currencies,
    setCurrencies,
    amount,
    setAmount,
    amountRange,
    setAmountRange,
    getFilterFromOperator,
    getOperatorAndAmountFromUrl,
    isActive,
  }
}
