import { useEffect } from 'react'
import {
  Route,
  Routes as Switch,
  Navigate,
  useLocation,
} from 'react-router-dom'
import { AdminRoute } from 'App/components/AdminRoute'
import Layout from 'App/components/Layout'
import { UnknownRoute } from 'App/components/UnknownRoute'
import { adminRoutes } from 'admin'
import { routes as errorRoutes } from 'errors'
import { useUserHomepage } from 'shared/permissions/hooks'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { routes } from './routes'

const Routes = () => {
  const { merchantAccountId } = useMultiMerchant()
  const userHomepage = useUserHomepage(merchantAccountId)
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Switch>
      <Route element={<Layout />}>
        {merchantAccountId && (
          <>
            <Route path="" element={<Navigate replace to={userHomepage} />} />
            <Route path="/merchants/:merchantAccountId">
              <Route path="" element={<Navigate replace to={userHomepage} />} />
              {routes.map(
                ({
                  key,
                  path,
                  longTitle,
                  title,
                  component: Component,
                  props = {},
                }) => (
                  <Route
                    key={key}
                    path={path}
                    element={
                      <Component {...props} title={longTitle || title} />
                    }
                  />
                )
              )}
            </Route>
            <Route path="/admin" element={<AdminRoute />}>
              {adminRoutes.map(({ key, title, path, component: Component }) => (
                <Route
                  key={key}
                  path={path}
                  element={<Component title={title} />}
                />
              ))}
              <Route
                path=""
                element={() => <Navigate replace to="/admin/merchants" />}
              />
            </Route>
            <Route>
              {errorRoutes.map(({ key, title, path, component: ErrorPage }) => (
                <Route
                  key={key}
                  path={path}
                  element={<ErrorPage title={title} />}
                />
              ))}
            </Route>
            <Route path="*" element={<UnknownRoute />} />
          </>
        )}
      </Route>
    </Switch>
  )
}

export default Routes
export { routes }
