import { PoutineProvider } from '@gr4vy/poutine-react'
import * as Sentry from '@sentry/react'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { forwardRef } from 'react'
import ReactHelmet from 'react-helmet'
import { BrowserRouter, Link } from 'react-router-dom'
import MerchantProvider from 'App/contexts/MerchantProvider'
import Routes from 'App/routes'
import { SessionProvider } from 'session'
import 'antd/dist/reset.css'
import '@gr4vy/poutine-react/dist/style.css'
import 'App/global.scss'
import '@fontsource/jetbrains-mono'
import '@fontsource/jetbrains-mono/500.css'
import '@fontsource/jetbrains-mono/700.css'
import 'shared/utils/dayjs-config'
import { SSOProvider } from 'session/contexts/SSOProvider'
import { TokenProvider } from 'session/services/sessions'
import { merchantFaviconPath, backgroundColor } from 'shared/constants/merchant'
import { ThemeProvider } from './components/ThemeProvider'
import { handleQueryError } from './helpers/query-error'

const href = new URL(window.location.href)
const token = href.searchParams.get('token') || undefined
const provider = href.searchParams.get('provider') || undefined
const returnTo = href.searchParams.get('returnTo') || undefined

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          const statusCode = error.response?.status || 200

          if ([401, 403, 404].includes(statusCode)) {
            return false
          }

          return failureCount < 3
        },
      },
    },
    queryCache: new QueryCache({
      onError: handleQueryError,
    }),
  })

  return (
    <>
      <ReactHelmet
        defaultTitle="Dashboard - Gr4vy"
        titleTemplate="%s - Dashboard - Gr4vy"
      >
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${merchantFaviconPath}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${merchantFaviconPath}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${merchantFaviconPath}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`${merchantFaviconPath}/manifest.json`} />
        <link
          rel="mask-icon"
          href={`${merchantFaviconPath}/safari-pinned-tab.svg`}
          color="#5bbad5"
        />
        <link rel="shortcut icon" href={`${merchantFaviconPath}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${merchantFaviconPath}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${merchantFaviconPath}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${merchantFaviconPath}/favicon-16x16.png`}
        />
        <link
          rel="mask-icon"
          href={`${merchantFaviconPath}/safari-pinned-tab.svg`}
          color="#319e9d"
        />
        <meta name="msapplication-TileColor" content="#00aba9" />
        <meta name="theme-color" content={backgroundColor} />
      </ReactHelmet>
      <PoutineProvider
        linkComponent={forwardRef(function CustomLink({ href, ...rest }, ref) {
          if (href?.startsWith('https')) {
            return <a href={href} {...rest} />
          }
          return <Link to={`${href}`} {...rest} ref={ref} />
        })}
      >
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <SSOProvider>
              <ThemeProvider>
                <SessionProvider
                  token={token}
                  provider={provider as TokenProvider | undefined}
                  returnTo={returnTo}
                >
                  <MerchantProvider>
                    <Routes />
                  </MerchantProvider>
                  {process.env.REACT_APP_ENABLE_REACT_QUERY_DEV_TOOLS ===
                    'true' && (
                    <ReactQueryDevtools buttonPosition="bottom-right" />
                  )}
                </SessionProvider>
              </ThemeProvider>
            </SSOProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </PoutineProvider>
    </>
  )
}

export default Sentry.withProfiler(App)
