import {
  Box,
  Button,
  Divider,
  Icon,
  Popover,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { CSSProperties, useMemo, useState } from 'react'
import { isSandbox } from 'shared/helpers/is-sandbox'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import styles from './MerchantAccountDropdown.module.scss'

interface MerchantAccountDropdownProps {
  merchantAccounts: MerchantAccount[]
  active?: boolean
  onChange: (merchantAccountId: string) => void
  onManageClick: () => void
  value?: string
}

export const MerchantAccountDropdown = ({
  merchantAccounts,
  active,
  onChange,
  value,
  onManageClick,
}: MerchantAccountDropdownProps) => {
  const [open, setOpen] = useState(false)
  const { canAccessAdminMode, isAdminMode, canAccessMerchants } =
    useMultiMerchant()

  const isReadOnly = useMemo(
    () => merchantAccounts.length === 1 && !canAccessAdminMode,
    [canAccessAdminMode, merchantAccounts.length]
  )

  const merchantAccountDisplayName = useMemo(
    () =>
      merchantAccounts.find((merchantAccount) => value === merchantAccount.id)
        ?.displayName,
    [merchantAccounts, value]
  )

  const label =
    active && merchantAccountDisplayName
      ? merchantAccountDisplayName
      : 'Select a merchant'

  const onChangeMerchantAccountId = (newMerchantAccountId: string) => {
    onChange(newMerchantAccountId)
    setOpen(false)
  }

  const handleManageClick = () => {
    setOpen(false)
    onManageClick()
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <Button
          className={clsx([
            styles.trigger,
            open && styles.active,
            isReadOnly && styles.readOnly,
          ])}
          tabIndex={isReadOnly ? -1 : 0}
          style={
            {
              '--char-limit':
                isSandbox && process.env.REACT_APP_MERCHANT_LOGO
                  ? '28ch'
                  : '42ch',
            } as CSSProperties
          }
        >
          <Text truncate>{label}</Text>
          {!isReadOnly && (
            <Icon
              name={open ? 'chevron-up-lg' : 'chevron-down-lg'}
              style={{ flexShrink: 0 }}
            />
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        side="bottom"
        avoidCollisions={false}
        className={styles.content}
      >
        <Stack gap={16} paddingX={24} paddingY={24}>
          <Stack
            background="white"
            gap={8}
            borderRadius="rounded"
            className={styles.accounts}
          >
            {merchantAccounts
              .sort((a, b) =>
                a.displayName.localeCompare(b.displayName, undefined, {
                  sensitivity: 'base',
                })
              )
              .map((merchantAccount) => (
                <Box
                  key={merchantAccount.id}
                  className={clsx([
                    styles.item,
                    !isAdminMode &&
                      merchantAccount.id === value &&
                      styles.active,
                  ])}
                  borderRadius="rounded"
                  tabIndex={0}
                  onClick={() => onChangeMerchantAccountId(merchantAccount.id)}
                >
                  <Text variant="med3">{merchantAccount.displayName}</Text>
                  <Text fontFamily="mono" color="gray70">
                    {merchantAccount.id}
                  </Text>
                </Box>
              ))}
          </Stack>
          {canAccessMerchants && (
            <Stack gap={16}>
              <Box alignSelf="center" borderRadius="rounded" width={160}>
                <Divider width="md" />
              </Box>
              <Box borderRadius="rounded">
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={handleManageClick}
                >
                  Manage merchants
                  <Icon name="arrow-right-md" />
                </Button>
              </Box>
            </Stack>
          )}
        </Stack>
      </Popover.Content>
    </Popover>
  )
}
