import { Form, FormItemProps } from 'antd'
import styles from './FormItem.module.scss'
const FormItem = ({ children, style, ...props }: FormItemProps) => (
  <Form.Item
    className={styles.formItem}
    style={{ marginBottom: 0, ...style }}
    labelAlign="left"
    {...props}
  >
    {children}
  </Form.Item>
)

export default FormItem
