import client from 'shared/services/client'
import { Buyer, PaymentMethodDetails } from 'transactions/services'

export interface PaymentMethod {
  type: 'payment-method'
  id: string
  method: string
  mode: string
  label: string
  scheme: string
  expirationDate: string
  approvalUrl: string
  country: string
  currency: string
  details: PaymentMethodDetails
  status: string
  externalIdentifier: string
  buyer: Buyer
  createdAt: string
  updatedAt: string
  lastReplacedAt: string
  fingerprint?: string
  additionalSchemes?: string[]
}

export const getPaymentMethod = async (id: string): Promise<PaymentMethod> => {
  const response = await client.get(`/payment-methods/${id}`)
  return response.data
}
