import { Auth0Provider } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

export const SSOProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const audience =
    process.env.REACT_APP_AUTH0_AUDIENCE ||
    `https://api.${document.location.host}`

  if (
    process.env.REACT_APP_AUTH0_ORG_ID &&
    process.env.REACT_APP_AUTH0_CLIENT_ID
  ) {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || 'https://auth.gr4vy.com'}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          organization: process.env.REACT_APP_AUTH0_ORG_ID,
          redirectUri: `${window.location.origin}/callback`,
          connection: process.env.REACT_APP_AUTH0_CONNECTION,
          audience: audience,
        }}
        onRedirectCallback={(appState) => {
          navigate('/callback', {
            replace: true,
            state: { returnTo: appState?.returnTo },
          })
        }}
      >
        {children}
      </Auth0Provider>
    )
  }
  return <>{children}</>
}
