import {
  ConnectionDefinition,
  ConnectionDefinitionCategory,
  ConnectionDefinitionGroup,
  Connection as ConnectionDef,
} from 'connections/services'

export enum Connection {
  catalog = 'catalog',
  configured = 'configured',
}

export const connectionNames: Record<Connection, string> = {
  [Connection.catalog]: 'Catalog',
  [Connection.configured]: 'Configured',
}

export const connectionDefinitionGroupNames: Record<
  ConnectionDefinitionGroup,
  string
> = {
  [ConnectionDefinitionGroup.payments]: 'Payments',
  [ConnectionDefinitionGroup.other]: 'Other',
}

export interface ConnectionGroupDetails {
  name: string
  categories: ConnectionDefinitionCategory[]
}

export const categoryToGroupMap = {
  [ConnectionDefinitionCategory.card]: ConnectionDefinitionGroup.payments,
  [ConnectionDefinitionCategory.bank]: ConnectionDefinitionGroup.payments,
  [ConnectionDefinitionCategory.bnpl]: ConnectionDefinitionGroup.payments,
  [ConnectionDefinitionCategory.wallet]: ConnectionDefinitionGroup.payments,
  [ConnectionDefinitionCategory.cash]: ConnectionDefinitionGroup.payments,
  [ConnectionDefinitionCategory.storedCredit]:
    ConnectionDefinitionGroup.payments,
  [ConnectionDefinitionCategory.antiFraud]: ConnectionDefinitionGroup.other,
}

export interface Catalog {
  [ConnectionDefinitionGroup.payments]: CatalogGroup
  [ConnectionDefinitionGroup.other]: CatalogGroup
  count?: number
}

export interface CatalogGroup {
  name: string
  count?: number
  categories: Partial<
    Record<ConnectionDefinitionCategory, ConnectionDefinition[]>
  >
}

export interface Configured {
  [ConnectionDefinitionGroup.payments]: ConfiguredGroup
  [ConnectionDefinitionGroup.other]: ConfiguredGroup
  count?: number
}

export interface ConfiguredGroup {
  name: string
  count?: number
  connections: ConnectionDef[]
}

export const connectionGroups: Record<
  ConnectionDefinitionGroup,
  ConnectionGroupDetails
> = {
  [ConnectionDefinitionGroup.payments]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.payments],
    categories: [
      ConnectionDefinitionCategory.card,
      ConnectionDefinitionCategory.bank,
      ConnectionDefinitionCategory.bnpl,
      ConnectionDefinitionCategory.wallet,
      ConnectionDefinitionCategory.cash,
      ConnectionDefinitionCategory.storedCredit,
    ],
  },
  [ConnectionDefinitionGroup.other]: {
    name: connectionDefinitionGroupNames[ConnectionDefinitionGroup.other],
    categories: [ConnectionDefinitionCategory.antiFraud],
  },
}

export const connectionDefinitionCategoryNames: Record<
  ConnectionDefinitionCategory,
  string
> = {
  [ConnectionDefinitionCategory.card]: 'Card',
  [ConnectionDefinitionCategory.bank]: 'Bank',
  [ConnectionDefinitionCategory.bnpl]: 'Buy Now Pay Later',
  [ConnectionDefinitionCategory.wallet]: 'Wallet',
  [ConnectionDefinitionCategory.cash]: 'Cash',
  [ConnectionDefinitionCategory.storedCredit]: 'Gift Cards',
  [ConnectionDefinitionCategory.antiFraud]: 'Anti-fraud',
}
