import { useMemo } from 'react'
import { getCurrencyByCode } from 'shared/helpers/currency'

export const useCurrencies = (supportedCurrencies: string[] | undefined) =>
  useMemo(
    () =>
      supportedCurrencies
        ?.map((value) => ({
          value,
          label: `${value} - ${getCurrencyByCode(value)?.currency}`,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [supportedCurrencies]
  )
