import {
  connectionDefinitionCategoryNames,
  Catalog,
  connectionGroups,
} from 'connections/constants'
import { SideNav } from 'shared/components/SideNav/SideNav'

export const ConnectionsCatalogPageNav = ({
  catalog,
}: {
  catalog: Catalog
}) => (
  <SideNav
    items={[
      {
        key: catalog.payments.name,
        title: <SideNav.Title>{catalog.payments.name}</SideNav.Title>,
        className: 'title',
        href: '',
        children: connectionGroups.payments.categories.map((category) => ({
          key: category,
          title: connectionDefinitionCategoryNames[category],
          href: `#${category}`,
        })),
      },
      {
        key: catalog.other.name,
        title: <SideNav.Title>{catalog.other.name}</SideNav.Title>,
        href: '',
        className: 'title',
        children: connectionGroups.other.categories.map((category) => ({
          key: category,
          title: connectionDefinitionCategoryNames[category],
          href: `#${category}`,
        })),
      },
    ]}
  />
)
