import { useReportsPermission } from 'reports/hooks/use-reports-permission'
import { pathToFlow, pathToReport, pathToTransaction } from 'shared/paths'
import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermissions } from './use-resource-permissions'

export const useUserHomepage = (merchantAccountId: string) => {
  const [transactionsAccess, flowsAccess, reportsAccess] =
    useResourcePermissions(
      [Resource.transactions, Resource.flows, Resource.reports],
      AccessLevel.read
    )
  const { hasWritePermission } = useReportsPermission()

  if (!merchantAccountId) {
    return '/'
  }

  const reportsHomePage = hasWritePermission
    ? pathToReport.allReports(merchantAccountId)
    : pathToReport.generatedReports(merchantAccountId)

  if (transactionsAccess) {
    return pathToTransaction.transactions(merchantAccountId)
  }

  if (flowsAccess) {
    return pathToFlow.flows(merchantAccountId)
  }

  if (reportsAccess) {
    return reportsHomePage
  }

  return '/'
}
