import { Description } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import {
  useCardSchemeDefinition,
  usePaymentMethodDefinition,
} from 'shared/hooks'
import { MaskedCardNumber } from 'transactions/components/MaskedCardNumber'
import { giftCardIconUrl } from 'transactions/constants/giftCards'
import { GiftCardRedemption, PaymentMethod } from 'transactions/services'

interface PaymentMethodDescriptionProps {
  paymentMethod: PaymentMethod | null
  giftCardRedemptions: GiftCardRedemption[]
}

export const PaymentMethodDescription = ({
  paymentMethod,
  giftCardRedemptions,
}: PaymentMethodDescriptionProps) => {
  const definition = usePaymentMethodDefinition(paymentMethod?.method)
  const cardSchemeDefinition = useCardSchemeDefinition(paymentMethod?.scheme)
  let text: ReactNode
  let icon: string | undefined

  if (paymentMethod) {
    text = paymentMethod?.label || definition?.displayName
    icon = definition?.iconUrl

    if (paymentMethod?.method === 'card' && paymentMethod?.label) {
      text = (
        <MaskedCardNumber
          paymentMethod={paymentMethod}
          giftCardRedemptions={giftCardRedemptions}
        />
      )
      icon = cardSchemeDefinition?.iconUrl || definition?.iconUrl
    }

    if (
      (definition?.id === 'network-token' || definition?.typeof === 'card') &&
      paymentMethod?.label
    ) {
      text = (
        <MaskedCardNumber
          paymentMethod={paymentMethod}
          giftCardRedemptions={giftCardRedemptions}
        />
      )
      icon = definition?.iconUrl
    }
  } else if (giftCardRedemptions[0]) {
    text = (
      <MaskedCardNumber
        paymentMethod={null}
        giftCardRedemptions={giftCardRedemptions}
      />
    )
    icon = giftCardIconUrl
  }

  return (
    <Description>
      <Description.Icon size={24} src={icon} />
      {!!text && (
        <Description.Text alignItems="flex-end">
          {text as string}
        </Description.Text>
      )}
    </Description>
  )
}
