import { Stack } from '@gr4vy/poutine-react'
import { UseQueryResult } from '@tanstack/react-query'
import { GettingStartedBanner } from 'admin/components/GettingStartedBanner'
import { PageLayout } from 'shared/components/PageLayout'
import { CollectionResponse } from 'shared/services/client'
import { TransactionFilters as Filters } from 'shared/services/transactions'
import TransactionFilters from 'transactions/components/TransactionFilters'
import TransactionTable from 'transactions/components/TransactionTable'
import { useGettingStarted } from 'transactions/hooks/use-getting-started'
import { TransactionSummary } from 'transactions/services/transactions'

type TransactionBrowsePageProps = {
  title: string
  filters: Partial<Filters>
  page: UseQueryResult<CollectionResponse<TransactionSummary>>
  onFilter: (filter: Filters) => void
}

const TransactionBrowsePageView = ({
  title,
  page,
  filters,
  onFilter,
}: TransactionBrowsePageProps) => {
  const { showGettingStarted, hasTransactions } = useGettingStarted()

  return (
    <PageLayout title={title} gap={showGettingStarted ? 'none' : undefined}>
      <Stack gap={32}>
        {showGettingStarted && (
          <GettingStartedBanner
            paddingY={40}
            hasTransactions={hasTransactions}
          />
        )}
        <TransactionFilters filters={filters} onFilter={onFilter} />
        <TransactionTable
          page={page}
          pagination={{
            filters,
            onFilter,
          }}
        />
      </Stack>
    </PageLayout>
  )
}

export default TransactionBrowsePageView
