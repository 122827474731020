import { Stack } from '@gr4vy/poutine-react'
import { Catalog } from 'connections/constants'
import { ConnectionDefinitionGroup } from 'connections/services'
import { CatalogGroup } from './CatalogGroup'

export const CatalogGroups = ({ catalog }: { catalog: Catalog }) => (
  <Stack style={{ gap: '96px' }}>
    <CatalogGroup
      catalogGroup={catalog.payments}
      catalogDefinition={ConnectionDefinitionGroup.payments}
    />
    <CatalogGroup
      catalogGroup={catalog.other}
      catalogDefinition={ConnectionDefinitionGroup.other}
    />
  </Stack>
)
