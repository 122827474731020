import { ColorBlue100 } from '@gr4vy/poutine-tokens'

export const merchantLogo =
  !!process.env.REACT_APP_MERCHANT_LOGO &&
  process.env.REACT_APP_MERCHANT_LOGO !== 'false'
    ? process.env.REACT_APP_MERCHANT_LOGO
    : false

export const merchantFaviconPath =
  !!process.env.REACT_APP_MERCHANT_FAVICON_PATH &&
  process.env.REACT_APP_MERCHANT_FAVICON_PATH !== 'false'
    ? `/img/favicons/${process.env.REACT_APP_MERCHANT_FAVICON_PATH}`
    : '/img/favicons/gr4vy'

export const merchantLogoFooterPosition =
  !!process.env.REACT_APP_MERCHANT_LOGO_FOOTER_POSITION &&
  process.env.REACT_APP_MERCHANT_LOGO_FOOTER_POSITION !== 'false'
    ? process.env.REACT_APP_MERCHANT_LOGO_FOOTER_POSITION
    : false

export const backgroundColor =
  !!process.env.REACT_APP_MERCHANT_BGCOLOR &&
  process.env.REACT_APP_MERCHANT_BGCOLOR !== 'false'
    ? process.env.REACT_APP_MERCHANT_BGCOLOR
    : ColorBlue100
