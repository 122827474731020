import { Icon, Stack, Text } from '@gr4vy/poutine-react'
import { Input } from 'antd'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { ChangeEvent, KeyboardEvent, useCallback } from 'react'
import styles from './DebouncedSearchBar.module.scss'

interface DebouncedSearchBarProps {
  placeholder: string
  defaultValue?: string
  onChange: (value?: string) => void
  size?: 'small' | 'medium'
}

const DebouncedSearchBar = ({
  placeholder,
  defaultValue,
  onChange,
  size = 'medium',
}: DebouncedSearchBarProps) => {
  const onSearch = useCallback(
    ({ target }: KeyboardEvent | ChangeEvent) => {
      onChange((target as HTMLInputElement).value || undefined)
    },
    [onChange]
  )

  const debouncedSearch = debounce(onSearch, 400)

  const onChangeSearch = ({ target }: KeyboardEvent | ChangeEvent) => {
    if ((target as HTMLInputElement).value === '') {
      debouncedSearch.cancel()
      return onSearch({ target } as ChangeEvent)
    }
    return debouncedSearch({ target } as ChangeEvent)
  }

  return (
    <Stack as="label" gap={8}>
      <Text margin="none" fontWeight="medium">
        Search
      </Text>
      <Input
        allowClear
        name="search"
        placeholder={placeholder}
        onPressEnter={onSearch}
        onChange={onChangeSearch}
        defaultValue={defaultValue}
        prefix={<Icon name="magnifying-glass" size="small" color="black" />}
        className={clsx(size === 'small' && styles.inputSmall)}
      />
    </Stack>
  )
}

export default DebouncedSearchBar
