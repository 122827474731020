import {
  atoms,
  Tag,
  Flex,
  Popover,
  Stack,
  Text,
  ToggleSwitch,
  FilterButton,
  Divider,
  Skeleton,
} from '@gr4vy/poutine-react'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { PaymentService, PaymentServiceDefinition } from 'connections/services'
import { PopoverMenu } from 'shared/components/PopoverMenu'
import styles from './TransactionColumnFilter.module.scss'

type PickedPaymentService = Pick<
  PaymentService,
  'id' | 'active' | 'method' | 'isDeleted'
>

export type ConnectionItemProps = PickedPaymentService &
  Pick<PaymentServiceDefinition, 'displayName' | 'iconUrl'>
export type PaymentMethodItemProps = Omit<
  ConnectionItemProps,
  'method' | 'isDeleted'
> & {
  method?:
    | PaymentService['method']
    | 'applepay'
    | ['googlepay', 'googlepay_pan_only']
    | 'click-to-pay'
    | 'network-token'
}

export enum FilterKey {
  PaymentMethod = 'method',
  Connection = 'id',
  GiftCardRedemption = 'hasGiftCardRedemptions',
}

type TransactionColumnFilterProps = {
  label: string
  toggleLabel: string
  onCheckedChange?(checked: boolean): void
  isLoading: boolean
  filter: {
    key: FilterKey
    value: string | string[] | undefined
  }
  data: ConnectionItemProps[] | PaymentMethodItemProps[]
  onToggleFilter(item: ConnectionItemProps | PaymentMethodItemProps): void
}

const SkeletonItems = Array.from({ length: 6 }, (_, k) => k).map((item) => (
  <PopoverMenu.Item key={item}>
    <Skeleton minWidth={32}>
      <Skeleton.Box borderRadius="rounded" height={32} />
    </Skeleton>
    <Skeleton width={200}>
      <Skeleton.Paragraph lines={1} />
    </Skeleton>
  </PopoverMenu.Item>
))

const TransactionColumnFilter = ({
  label,
  toggleLabel,
  onToggleFilter,
  onCheckedChange,
  filter,
  isLoading,
  data,
}: TransactionColumnFilterProps) => {
  const [showAllItems, setShowAllItems] = useState(false)

  const handleToggleChangeVisibility = (checked: boolean) => {
    setShowAllItems(checked)
    onCheckedChange?.(checked)
  }

  const isItemActive = (item: PaymentMethodItemProps) =>
    item.id === 'gift_card'
      ? filter.key === FilterKey.GiftCardRedemption && filter.value === 'true'
      : isEqual(
          filter?.value,
          item[
            filter.key as keyof (PaymentMethodItemProps | ConnectionItemProps)
          ]
        )

  return (
    <Popover>
      <Popover.Trigger asChild>
        <FilterButton data-selected={!!filter.value}>
          {label}
          {filter.value ? ' / 1' : null}
        </FilterButton>
      </Popover.Trigger>
      <Popover.Content align="start" side="bottom" avoidCollisions={false}>
        <Stack gap={8} className={styles.contentWrapper}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap={16}
            paddingY={8}
          >
            <Text as="label">{toggleLabel}</Text>
            <ToggleSwitch
              checked={showAllItems}
              onCheckedChange={handleToggleChangeVisibility}
            />
          </Flex>
          <Divider width="sm" />
          <PopoverMenu>
            {isLoading && !!data
              ? SkeletonItems
              : data
                  ?.filter(
                    (item) =>
                      showAllItems ||
                      (item.active && !(item as ConnectionItemProps)?.isDeleted)
                  )
                  ?.map((item) => (
                    <PopoverMenu.Item
                      key={item.id}
                      onClick={() => onToggleFilter(item)}
                      active={isItemActive(item)}
                      title={item.displayName}
                    >
                      <img
                        alt={item.displayName}
                        className={atoms({ height: 24, width: 24 })}
                        src={item.iconUrl}
                      />

                      <Text as="span" flexGrow={1} truncate>
                        {item.displayName}
                      </Text>
                      {!item.active &&
                        !(item as ConnectionItemProps)?.isDeleted && (
                          <Tag>Inactive</Tag>
                        )}
                      {!!(item as ConnectionItemProps).isDeleted && (
                        <Tag>Deleted</Tag>
                      )}
                    </PopoverMenu.Item>
                  ))}
          </PopoverMenu>
        </Stack>
      </Popover.Content>
    </Popover>
  )
}

export default TransactionColumnFilter
