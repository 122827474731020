import { Drawer, DrawerProps, Heading, Stack } from '@gr4vy/poutine-react'
import { EventCodeBlock } from 'transactions/components//EventCodeBlock'
import { formatSectionName } from 'transactions/helpers'
import {
  PaymentMethod,
  Transaction,
  TransactionEvent,
} from 'transactions/services/transactions'
import { AntiFraudDecisionErrorEvent } from './Events/AntiFraudDecisionErrorEvent'
import { AntiFraudDecisionEvent } from './Events/AntiFraudDecisionEvent'
import { AntiFraudDecisionSkippedEvent } from './Events/AntiFraudDecisionSkippedEvent'
import { AntiFraudTransactionStatusUpdateErrorEvent } from './Events/AntiFraudTransactionStatusUpdateErrorEvent'
import { AntiFraudTransactionStatusUpdateEvent } from './Events/AntiFraudTransactionStatusUpdateEvent'
import { AntiFraudWebhookEvent } from './Events/AntiFraudWebhookEvent'
import { BINLookupRequestEvent } from './Events/BINLookupRequestEvent'
import { ConnectorAuthorizationResponseEvent } from './Events/ConnectorAuthorizationResponseEvent'
import { ConnectorCaptureResponseEvent } from './Events/ConnectorCaptureResponseEvent'
import { ConnectorVoidDeclinedEvent } from './Events/ConnectorVoidDeclinedEvent'
import { ConnectorVoidFailedEvent } from './Events/ConnectorVoidFailedEvent'
import { ConnectorVoidSucceededEvent } from './Events/ConnectorVoidSucceededEvent'
import { DigitalWalletApplePayTokenDecryptedEvent } from './Events/DigitalWalletApplePayTokenDecryptedEvent'
import { DigitalWalletClickToPayTokenDecryptedEvent } from './Events/DigitalWalletClickToPayTokenDecryptedEvent'
import { DigitalWalletGooglePayTokenDecryptedEvent } from './Events/DigitalWalletGooglePayTokenDecryptedEvent'
import { ExternalTransactionRequestEvent } from './Events/ExternalTransactionRequestEvent'
import { GiftCardActionFailedEvent } from './Events/GiftCardActionFailedEvent'
import { GiftCardActionSucceededEvent } from './Events/GiftCardActionSucceededEvent'
import { NetworkTokenFailedEvent } from './Events/NetworkTokenFailedEvent'
import { NetworkTokenSucceededEvent } from './Events/NetworkTokenSucceededEvent'
import { TheGivingBlockConvertedReceivedEvent } from './Events/TheGivingBlockConvertedReceivedEvent'
import { ThreeDSecureAuthenticationRequestEvent } from './Events/ThreeDSecureAuthenticationRequestEvent'
import { ThreeDSecurePreparationRequestEvent } from './Events/ThreeDSecurePreparationRequestEvent'
import { ThreeDSecureRequestErrorEvent } from './Events/ThreeDSecureRequestErrorEvent'
import { ThreeDSecureResultRequestEvent } from './Events/ThreeDSecureResultRequestEvent'
import { ThreeDSecureSuccessEvent } from './Events/ThreeDSecureSuccessEvent'

const DefaultEvent = ({ event }: { event: TransactionEvent }) => (
  <Stack gap={32}>
    {Object.keys(event.context)
      .sort()
      .map((item, index) => (
        <Stack key={index} gap={8}>
          <Heading as="h5" level={5}>
            {formatSectionName(item)}
          </Heading>
          <EventCodeBlock code={event.context[item]} />
        </Stack>
      ))}
  </Stack>
)

const eventComponents = {
  'anti-fraud-decision': AntiFraudDecisionEvent,
  'anti-fraud-decision-error': AntiFraudDecisionErrorEvent,
  'anti-fraud-decision-skipped': AntiFraudDecisionSkippedEvent,
  'anti-fraud-transaction-status-update': AntiFraudTransactionStatusUpdateEvent,
  'anti-fraud-transaction-status-update-error':
    AntiFraudTransactionStatusUpdateErrorEvent,
  'anti-fraud-webhook': AntiFraudWebhookEvent,
  'bin-lookup-request': BINLookupRequestEvent,
  'digital-wallet-google-pay-token-decrypted':
    DigitalWalletGooglePayTokenDecryptedEvent,
  'digital-wallet-click-to-pay-token-decrypted':
    DigitalWalletClickToPayTokenDecryptedEvent,
  'digital-wallet-apple-pay-token-decrypted':
    DigitalWalletApplePayTokenDecryptedEvent,
  'three-d-secure-success': ThreeDSecureSuccessEvent,
  'three-d-secure-preparation-request': ThreeDSecurePreparationRequestEvent,
  'three-d-secure-authentication-request':
    ThreeDSecureAuthenticationRequestEvent,
  'three-d-secure-result-request': ThreeDSecureResultRequestEvent,
  'three-d-secure-request-error': ThreeDSecureRequestErrorEvent,
  'gift-card-redemption-succeeded': GiftCardActionSucceededEvent,
  'gift-card-refund-succeeded': GiftCardActionSucceededEvent,
  'gift-card-reversal-succeeded': GiftCardActionSucceededEvent,
  'gift-card-redemption-failed': GiftCardActionFailedEvent,
  'gift-card-refund-failed': GiftCardActionFailedEvent,
  'network-token-provision-succeeded': NetworkTokenSucceededEvent,
  'network-token-provision-failed': NetworkTokenFailedEvent,
  'network-token-cryptogram-provision-succeeded': NetworkTokenSucceededEvent,
  'network-token-cryptogram-provision-failed': NetworkTokenFailedEvent,
  'payment-connector-external-transaction-request':
    ExternalTransactionRequestEvent,
  'payment-connector-response-transaction-declined':
    ConnectorAuthorizationResponseEvent,
  'payment-connector-response-transaction-authorization-failed':
    ConnectorAuthorizationResponseEvent,
  'payment-connector-response-transaction-authorization-succeeded':
    ConnectorAuthorizationResponseEvent,
  'payment-connector-response-transaction-capture-succeeded':
    ConnectorCaptureResponseEvent,
  'payment-connector-response-transaction-capture-failed':
    ConnectorCaptureResponseEvent,
  'payment-connector-response-transaction-capture-declined':
    ConnectorCaptureResponseEvent,
  'payment-connector-response-transaction-void-succeeded':
    ConnectorVoidSucceededEvent,
  'payment-connector-response-transaction-void-failed':
    ConnectorVoidFailedEvent,
  'payment-connector-response-transaction-void-declined':
    ConnectorVoidDeclinedEvent,
  'the-giving-block-transaction-conversion-succeeded':
    TheGivingBlockConvertedReceivedEvent,
  default: DefaultEvent,
}

const renderEvent = ({
  event,
  paymentMethod,
}: {
  event?: TransactionEvent
  paymentMethod?: PaymentMethod
}) => {
  if (!event) {
    return null
  }

  const Component =
    eventComponents[event.name as keyof typeof eventComponents] ||
    eventComponents.default

  return <Component event={event} paymentMethod={paymentMethod} />
}

interface EventDrawerProps extends Omit<DrawerProps, 'children'> {
  event?: TransactionEvent
  transaction: Transaction
  paymentMethod?: PaymentMethod
}

export const EventDrawer = ({
  event,
  onClose,
  paymentMethod,
  ...rest
}: EventDrawerProps) => {
  return (
    <Drawer size="md" onClose={onClose} {...rest}>
      {renderEvent({ event, paymentMethod })}
    </Drawer>
  )
}
