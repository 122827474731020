import { useParams } from 'react-router-dom'
import { useTransactions } from 'admin/hooks/use-transactions'
import { useRoles } from 'users/hooks/use-roles'

export interface GettingStartedProps {
  isLoading: boolean
}

const MINIMUM_TRANSACTIONS = 20

export const useGettingStarted = () => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { isLoadingTransactions, numberOfTransactions } = useTransactions({
    merchantAccountId,
  })

  const { isAdmin } = useRoles()
  const showGettingStarted =
    isAdmin &&
    !isLoadingTransactions &&
    process.env.REACT_APP_GETTING_STARTED !== 'false' &&
    numberOfTransactions < MINIMUM_TRANSACTIONS

  return {
    showGettingStarted,
    hasTransactions: numberOfTransactions > 0,
  }
}
