import { getCurrencyByCode } from './currency'
import { is } from './is'

type Options = Intl.NumberFormatOptions & {
  digits?: number
  roundingIncrement?: number
}

export default (amount: any, options?: Options, locale = 'en-US') => {
  if (
    is.null(amount) ||
    is.undefined(amount) ||
    is.emptyStringOrWhitespace(amount)
  ) {
    return amount
  }
  const { currency, digits, ...rest } = {
    ...options,
    currency: options?.currency || 'USD',
  }
  const { digits: currencyDigits = 2 } = getCurrencyByCode(currency) || {}
  // Check if the current number of digits exceeds the number
  // of digits allowed by the currency.This is used by inputs
  // while typing, to allow rounding of the subunit portion.
  const outOfRangeDigits = digits && digits > currencyDigits

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: currencyDigits,
    maximumFractionDigits: currencyDigits,
    roundingIncrement: 1,
    ...rest,
  }).format(amount / 10 ** (outOfRangeDigits ? digits : currencyDigits))
}
